// import { useQuery } from "@apollo/client";
import React from "react";
import ReactQuill from "react-quill";
// import { GET_NOTE_BODY } from "../gqls/gqls";
import "../scss/quill.snow.css";
// import { TransitionGroup, CSSTransition } from "react-transition-group";

interface IPopupNoteContentProps {
 readonly noteId: string;
 readonly title: string;
 readonly onSubmit: (content: string) => void;
 readonly onClose: () => void;
}

enum EMode {
 Handling,
 ErrorResult,
 SuccessResult,
}

const getClassNameByMode = (mode: EMode): string => {
 switch (mode) {
  case EMode.Handling:
   return "handling";
  case EMode.ErrorResult:
   return "error-result";
  case EMode.Handling:
   return "success-result";
  default:
   return "";
 }
};

interface IStatus {
 readonly mode: EMode;
 readonly label: string;
}

export const PopupContentNote: React.FC<IPopupNoteContentProps> = (props: IPopupNoteContentProps) => {
 const [body, setBody] = React.useState<string>();
 const [status, setStatus] = React.useState<IStatus>({ mode: EMode.Handling, label: "Loading" });

 React.useEffect(() => {
  const url = process.env.REACT_APP_BACKEND_URL + "/note-get";
  const formData = new FormData();
  formData.append("note", props.noteId);
  window
   .fetch(url, {
    body: formData,
    method: "post",
   })
   .then(async res => {
    const data = await res.json();
    if (res.ok) {
     setBody(data["content"]);
     setStatus({ mode: EMode.SuccessResult, label: "" });
    } else {
     return Promise.reject(Error(data && data.message ? data.message : "Unable to load note"));
    }
   })
   .catch(err => {
    setBody("");
    setStatus({ mode: EMode.ErrorResult, label: "" });
   });
 }, []);

 const onSave = () => {
  if (body !== undefined) {
   setStatus({ mode: EMode.Handling, label: "" });
   const url = process.env.REACT_APP_BACKEND_URL + "/note-save";
   const noteId = props.noteId;
   const contentFile = new Blob([body]);
   const formData = new FormData();
   formData.append("note", noteId);
   formData.append("content", contentFile);
   window
    .fetch(url, {
     body: formData,
     method: "post",
    })
    .then(async res => {
     const data = await res.json();
     if (res.ok) {
      setStatus({ mode: EMode.SuccessResult, label: "Successfully saved" });
      props.onSubmit(body);
     } else {
      return Promise.reject(Error(data && data.message ? data.message : "Unable to save note"));
     }
    })
    .catch(err => {
     setStatus({ mode: EMode.ErrorResult, label: (err as Error).message });
    });
  }
 };

 return (
  // <TransitionGroup component={null}>
  //  <CSSTransition key={props.key} classNames="PageFade" timeout={400}>
  <div className="PopupGallery FormPopup">
   <form
    onSubmit={e => {
     e.preventDefault();
    }}>
    <div className="popup-head">
     <div className="title">{props.title}</div>
     <div className="close">
      <button type="button" onClick={props.onClose} />
     </div>
    </div>
    <div className="popup-body">
     <div className="popup-body-vbox1">
      {body !== undefined && (
       <div className="simple-body">
        <ReactQuill
         theme="snow"
         modules={{
          toolbar: [
           // [{ header: [1, 2, false] }],
           ["bold", "italic"], // 'underline','strike', 'blockquote'],
           [{ list: "ordered" }, { list: "bullet" }], // {'indent': '-1'}, {'indent': '+1'}],
           ["link", "image"],
           ["clean"],
          ],
         }}
         formats={["header", "bold", "italic", "underline", "strike", "blockquote", "list", "bullet", "indent", "link", "image"]}
         value={body}
         onChange={value => {
          if (status.label) setStatus({ ...status, label: "" });
          setBody(value);
         }}
        />
       </div>
      )}
     </div>
    </div>
    <div className="popup-footer">
     {status.mode === EMode.Handling ? (
      <div className="simple-loading">
       <span className="loader"></span>
      </div>
     ) : (
      <span className={"label " + getClassNameByMode(status.mode)}>{status.label}</span>
     )}
     <button type="submit" onClick={onSave} disabled={body === undefined || status.mode === EMode.Handling}>
      Save
     </button>
    </div>
   </form>
  </div>
  //  </CSSTransition>
  // </TransitionGroup>
 );
};
