import React from "react";
import { useMutation, useQuery } from "@apollo/client";
import { ADD_NOTE, GET_NOTES, UPDATE_NOTE } from "../gqls/gqls";
import { PageError } from "./PageError";
import { PopupContentNote } from "./PopupNoteContent";
import "../scss/quill.snow.css";

const htmlRegex = /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g;
interface IProps {
 readonly onPageReady: () => void;
}

interface IPropsQL extends IProps {
 readonly noteStats: TNoteStatsMap;
}
interface ILoadedProps extends IPropsQL {
 readonly nodes: TNoteNodesMap;
 readonly rootNodeIds: string[];
}

enum ENoteStat {
 NoFile = "NoFile",
 EmptyFile = "EmptyFile",
 EmptyContent = "EmptyContent",
 HasContent = "HasContent",
}

enum ENodeMode {
 Create = "Create",
 Update = "Update",
 Delete = "Delete",
 //  Show = "Show",
 //  Hide = "Hide",
 Content = "Content",
}

type TNoteStatsMap = { [key: string]: ENoteStat };

type TNoteNodesMap = { [key: string]: INoteNode };

interface INotesResult {
 readonly id: string;
 readonly pos: number;
 readonly name: string;
 readonly parent: {
  readonly id: string;
 } | null;
}

interface IResult {
 readonly notes: INotesResult[];
}

export const PageNotes: React.FC<IProps> = (props: IProps) => {
 const [noteStats, setNoteStats] = React.useState<TNoteStatsMap>();
 React.useEffect(() => {
  const url = process.env.REACT_APP_BACKEND_URL + "/note-stats";
  window
   .fetch(url, {
    method: "post",
   })
   .then(res => res.json())
   .then(res => {
    setNoteStats(res["stats"]);
   })
   .catch(err => {
    console.log("Content err", err); // TODO handle error
    setNoteStats({});
   });
 }, []);

 return noteStats ? <PageNotesQL onPageReady={props.onPageReady} noteStats={noteStats} /> : <div>...</div>;
};

const PageNotesQL: React.FC<IPropsQL> = (props: IPropsQL) => {
 const { data, loading, error } = useQuery<IResult>(GET_NOTES);

 if (loading) return <div>...</div>;
 if (error) return <PageError title={error.name} message={error.message} onPageReady={props.onPageReady} />;

 if (data) {
  const nodesResult = notes2nodes(data.notes);
  return <LoadedPageNotes nodes={nodesResult.nodes} rootNodeIds={nodesResult.rootNodeIds} {...props} />;
 }
 return <PageError title="Notes not loaded" onPageReady={props.onPageReady} />;
 //  return data ? <LoadedPageNotes notes={data.notes} {...props} /> : <PageError title="Notes not loaded" onPageReady={props.onPageReady} />;
};

interface INoteNode {
 readonly id: string;
 readonly pos: number;
 readonly name: string;
 readonly parentId: string | null;
 readonly childrenIds: string[];
}

interface INodesResult {
 readonly nodes: TNoteNodesMap;
 readonly rootNodeIds: string[];
}

const notes2nodes = (notes: INotesResult[]): INodesResult => {
 const rootNodeIds: string[] = [];
 const notExistingParentIds: { [key: string]: string[] } = {};
 const nodes: TNoteNodesMap = {};
 for (let i = 0; i < notes.length; i++) {
  const note = notes[i];
  nodes[note.id] = {
   id: note.id,
   pos: note.pos,
   name: note.name,
   parentId: note.parent ? note.parent.id : null,
   childrenIds: notExistingParentIds[note.id] ? [...notExistingParentIds[note.id]] : [],
  };
  if (note.parent) {
   if (nodes[note.parent.id]) {
    nodes[note.parent.id].childrenIds.push(note.id);
   } else {
    if (!notExistingParentIds[note.parent.id]) notExistingParentIds[note.parent.id] = [];
    notExistingParentIds[note.parent.id].push(note.id);
   }
  } else {
   rootNodeIds.push(note.id);
  }
 }
 return { rootNodeIds, nodes };
};

const getNodeNames = (nodes: TNoteNodesMap, id: string): string[] => {
 let res: string[] = [];
 let nodeId: string | null = id;
 while (nodeId !== null && nodes[nodeId]) {
  res.push(nodes[nodeId].name);
  nodeId = nodes[nodeId].parentId;
 }
 res.reverse();
 return res;
};

const getNodesByIds = (nodes: TNoteNodesMap, ids: string[]): INoteNode[] => {
 return ids
  .reduce((p, c) => {
   if (nodes[c]) p.push(nodes[c]);
   return p;
  }, [] as INoteNode[])
  .sort((a, b) => a.pos - b.pos);
};

interface IHandleNodeCreateResult {
 readonly createNote: {
  readonly id: string;
  readonly pos: number;
  readonly name: string;
 };
}

interface IHandleNodeUpdateResult {
 readonly updateNote: {
  readonly id: string;
  readonly pos: number;
  readonly name: string;
 };
}

interface IContentPopup {
 readonly id: string;
 readonly title: string;
}

const LoadedPageNotes: React.FC<ILoadedProps> = (props: ILoadedProps) => {
 //  const [popup, setPopup] = React.useState<INotePopup>();
 const [contentPopup, setContentPopup] = React.useState<IContentPopup>();
 const [nodes, setNodes] = React.useState<TNoteNodesMap>({ ...props.nodes });
 const [stats, setStats] = React.useState<TNoteStatsMap>({ ...props.noteStats });
 const [rootNodeIds, setRootNodeIds] = React.useState<string[]>([...props.rootNodeIds]);
 const [addNode, addNodeProcessing] = useMutation<IHandleNodeCreateResult>(ADD_NOTE);
 const [updateNode, updateNodeProcessing] = useMutation<IHandleNodeUpdateResult>(UPDATE_NOTE);
 //  const [deleteNode, deleteNodeProcessing] = useMutation<IHandleNodeCreateResult>(DELETE_NOTE);

 React.useEffect(() => {
  props.onPageReady();
 }, []);

 React.useEffect(() => {
  window.addEventListener("hashchange", hashChangeHandler);
  hashChangeHandler();
  return () => {
   window.removeEventListener("hashchange", hashChangeHandler);
  };
 }, []);

 const hashChangeHandler = () => {
  const hash = window.location.hash;
  const noteId = hash ? hash.substring(1) : null;
  const note = noteId ? nodes[noteId] : undefined;
  if (note) {
   setContentPopup({ id: note.id, title: getNodeNames(nodes, note.id).join(" / ") });
  } else {
   setContentPopup(undefined);
  }
 };

 const onNodeAddClick = (parentId: string) => {
  const parentNode = nodes[parentId];
  if (parentNode) {
   const inputName = window.prompt(`Enter ${parentNode.name}'s new child name`);
   if (inputName) {
    const name = inputName.trim();
    if (name) onNodeAdd(parentId, name);
   }
  }
 };

 const onNodeUpdateClick = (nodeId: string) => {
  const node = nodes[nodeId];
  if (node) {
   const inputName = window.prompt(`Enter new name`, node.name);
   if (inputName) {
    const name = inputName.trim();
    if (name) onNodeUpdate(nodeId, name);
   }
  }
 };

 const onNodeDeleteClick = (nodeId: string) => {
  const node = nodes[nodeId];
  if (node && window.confirm(`Sure to delete ${node.name}?`)) onNodeDelete(nodeId);
 };

 const onNodeAdd = async (parentId: string | null, name: string) => {
  try {
   const note = {
    name: name,
    parent: {
     connect: {
      id: parentId,
     },
    },
   };
   const res = await addNode({ variables: { note } });
   console.log("res.data", res);
   if (res.data) {
    await onNodeAdded({
     id: res.data.createNote.id,
     pos: res.data.createNote.pos,
     name: res.data.createNote.name,
     parentId: parentId,
     childrenIds: [],
    });
   } else {
    throw Error(`Some error: ${res.errors?.map(f => f.toString())}`);
   }
  } catch (err) {
   console.log("Create error", err);
  }
 };

 const onNodeAdded = (noteNode: INoteNode) => {
  console.log("onNodeAdded", noteNode);
  const _nodes = { ...nodes };
  if (noteNode.parentId && _nodes[noteNode.parentId]) _nodes[noteNode.parentId].childrenIds.push(noteNode.id);
  _nodes[noteNode.id] = noteNode;
  setNodes(_nodes);
  setContentPopup(undefined);
 };

 const onNodeUpdate = async (nodeId: string, name: string) => {
  try {
   const note = {
    name: name,
   };
   const res = await updateNode({ variables: { note, id: nodeId } });
   if (res.data) {
    await onNodeUpdated(res.data.updateNote.id, res.data.updateNote.name);
   } else {
    throw Error(`Some error: ${res.errors?.map(f => f.toString())}`);
   }
  } catch (err) {
   console.log("Update error", err);
  }
 };

 const onNodeUpdated = (noteId: string, name: string) => {
  console.log("onNodeUpdated", noteId);
  const _nodes = { ...nodes };
  if (_nodes[noteId]) {
   _nodes[noteId] = { ..._nodes[noteId], name };
   setNodes(_nodes);
  }
  setContentPopup(undefined);
 };

 const onNodeDelete = async (nodeId: string) => {
  try {
   const note = {
    isPublished: false,
   };
   const res = await updateNode({ variables: { note, id: nodeId } });
   if (res.data) {
    await onNodeDeleted(nodeId);
    // await onNodeUpdated(res.data.updateNote.id, res.data.updateNote.name);
   } else {
    throw Error(`Some error: ${res.errors?.map(f => f.toString())}`);
   }
  } catch (err) {
   console.log("Update error", err);
  }
 };

 const onNodeDeleted = (noteId: string) => {
  console.log("onNodeDeleted", noteId);
  const _nodes = { ...nodes };
  delete _nodes[noteId];
  setNodes(_nodes);
  // setContentPopup(undefined);
 };

 return (
  <div className={"Page PageNote"}>
   <div className="head">
    <div className="bc">
     <div className="section">Notes</div>
    </div>
   </div>
   <div className="body nodes-tree">
    {rootNodeIds.length > 0 && (
     <NotesLevelSimple
      nodes={nodes}
      stats={stats}
      nodeIds={rootNodeIds}
      onNodeClick={(nodeId, mode) => {
       switch (mode) {
        case ENodeMode.Create: {
         onNodeAddClick(nodeId);
         break;
        }
        case ENodeMode.Update: {
         onNodeUpdateClick(nodeId);
         break;
        }
        case ENodeMode.Delete: {
         onNodeDeleteClick(nodeId);
         break;
        }
        case ENodeMode.Content: {
         window.location.hash = nodeId;
         //  const node = nodes[nodeId];
         //  if (node) {
         //   setContentPopup({ id: node.id, title: node.name });
         //  }
         break;
        }
        default: {
         console.log("Undefined mode: ", mode);
        }
       }
      }}
     />
    )}
   </div>
   {contentPopup && (
    <PopupContentNote
     noteId={contentPopup.id}
     title={getNodeNames(nodes, contentPopup.id).join(" / ")}
     onClose={() => {
      window.location.hash = "";
      // setContentPopup(undefined);
     }}
     onSubmit={content => {
      let stat: ENoteStat = ENoteStat.EmptyFile;
      if (content.length > 0) {
       stat = ENoteStat.EmptyContent;
       if (content.indexOf("<img") >= 0) {
        stat = ENoteStat.HasContent;
       } else {
        const pureContent = content.replace(htmlRegex, "");
        if (pureContent.trim().length > 0) stat = ENoteStat.HasContent;
       }
      }
      const _stats = { ...stats };
      _stats[contentPopup.id] = stat;
      setStats(_stats);
     }}
    />
   )}
  </div>
 );
};

interface NotesLevelProps {
 readonly nodes: TNoteNodesMap;
 readonly stats: TNoteStatsMap;
 readonly nodeIds: string[];
 readonly onNodeClick: (nodeId: string, mode: ENodeMode) => void;
}

const NotesLevelSimple: React.FC<NotesLevelProps> = (props: NotesLevelProps) => {
 const nodes = getNodesByIds(props.nodes, props.nodeIds);

 return (
  <div className="nodes-level-simple">
   {nodes.map(node => (
    <div key={node.id} className="node">
     <div className="node-simple">
      <div className={"node-simple-name"}>
       <a
        href={"#" + node.id}
        className={props.stats[node.id] && props.stats[node.id] === ENoteStat.HasContent ? "with-content" : "wout-content"}
        // onClick={() => props.onNodeClick(node.id, ENodeMode.Content)}
       >
        {node.name}
       </a>
      </div>
      <div className="node-simple-controls">
       <input type={"checkbox"} className="trigger" />
       <span className="actions">
        <button onClick={() => props.onNodeClick(node.id, ENodeMode.Create)}>add child</button>
        <button onClick={() => props.onNodeClick(node.id, ENodeMode.Update)}>rename</button>
        {node.parentId && <button onClick={() => props.onNodeClick(node.id, ENodeMode.Delete)}>delete</button>}
       </span>
      </div>
     </div>
     {node.childrenIds.length > 0 && (
      <div className="nodes-sublevel">
       <NotesLevelSimple
        nodes={props.nodes}
        stats={props.stats}
        nodeIds={node.childrenIds}
        onNodeClick={(nodeId, mode) => props.onNodeClick(nodeId, mode)}
       />
      </div>
     )}
    </div>
   ))}
  </div>
 );
};
