import React from "react";
// import ReactDOM from "react-dom";
import ReactDOM from "react-dom/client";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import AppLoader from "./components/App";

import "./scss/index.scss";
import { appGetToken } from "./utils/auth";

const backend = process.env.REACT_APP_BACKEND_URL;
if (process.env.NODE_ENV === "development") console.log("Backend url", backend);
console.log("App version:", process.env.REACT_APP_VERSION);

const client = new ApolloClient({
 uri: `${backend}/api/graphql`,
 cache: new InMemoryCache(),
 credentials: "include",
 headers: {
  authorization: appGetToken(), //window.localStorage.getItem("a") ?? "",
 },
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
 <ApolloProvider client={client}>
  <AppLoader />
 </ApolloProvider>
);

// ReactDOM.render(
//  <ApolloProvider client={client}>
//   <AppLoader />
//  </ApolloProvider>,
//  document.getElementById("root")
// );
