import { DocumentRendererProps } from "./document-renderer";

export const renderers: DocumentRendererProps["renderers"] = {
 inline: {
  // link: (props) => {
  //  // console.log('Link', props);
  //  return (<a href={props.href}>{props.children}</a>)
  // }
 },
};
